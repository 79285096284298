import React, { useState, useEffect, useMemo } from 'react';
import UserChoosenStyle from './index.style';

import { connect } from 'react-redux';
import { Table, Input } from 'antd';
import { addUserAndFileupload, setUserChosen } from '../../../../actions/admin';
import { setStepCreateTest } from '../../../../actions/admin';
import { toast } from 'react-toastify';
const { Search } = Input;
const PAGE_SIZE = 10;

const UserChoosen = ({
  users,
  setStepCreateTest,
  minPeopleJoin,
  userChosen,
  setUserChosen,
}) => {
  const participantsData = useMemo(
    () => users.filter(user => user.role !== 1 && user.type === false),
    [users],
  );
  const [participants, setParticipants] = useState([...participantsData]);
  const [userIds, setUserIds] = useState([]);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setParticipants(participantsData);
  }, [participantsData]);
  console.log({ userIds });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
  ];

  const rowSelection = {
    selectedRowKeys: userIds,
    onChange: (selectedRowKeys, selectedRows) => {
      const selectedUsers = selectedRowKeys;
      const participantIds = participants.map(participant => participant._id);
      const unSelectedUsers = participantIds
        .slice((page - 1) * PAGE_SIZE, (page - 1) * PAGE_SIZE + PAGE_SIZE)
        .filter(item => !selectedUsers.includes(item));
      let newUserIds = [...new Set([...userIds, ...selectedUsers])];
      newUserIds = newUserIds.filter(item => !unSelectedUsers.includes(item));
      setUserIds(newUserIds);
      setUserChosen(newUserIds);
    },
  };

  const onClickedHandler = () => {
    if (userChosen.length !== minPeopleJoin) {
      return toast.error(`Bạn phải chọn đủ ${minPeopleJoin} người`);
    }
    //addUserAndFileupload(userChosen, test._id, sentencePath, audioPath);
    setStepCreateTest('step4');
  };

  const onSearch = value => {
    console.log({ value });
    setParticipants(
      participantsData.filter(participant => participant.email.includes(value)),
    );
  };

  const onChangePage = (currentPage, pageSize) => {
    setPage(currentPage);
  };

  return (
    <UserChoosenStyle>
      <h1 style={{ textAlign: 'center' }}>
        Chỉ định user tham gia bài test Private
      </h1>
      <p className="notice">
        Đã chọn {userIds.length}/{minPeopleJoin} người cho bài test
      </p>
      <Search
        placeholder="Nhập email"
        onSearch={onSearch}
        enterButton
        className="search-input"
      />
      <Table
        className="table"
        rowSelection={rowSelection}
        columns={columns}
        rowKey="_id"
        bordered
        dataSource={participants}
        pagination={{ onChange: onChangePage }}
      />
      <button className="btn btn-primary button" onClick={onClickedHandler}>
        Xác nhận và chuyển sang bước tiếp theo
      </button>
    </UserChoosenStyle>
  );
};

const mapStateToProps = state => {
  return {
    users: state.admin.users,
    test: state.admin.test,
    minPeopleJoin: state.admin.test.minPeopleJoin,
    userChosen: state.admin.userChosen,
  };
};

export default connect(
  mapStateToProps,
  { addUserAndFileupload, setStepCreateTest, setUserChosen },
)(UserChoosen);
