import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Checkbox, Typography, Button, Divider, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import StyleDataConstruction from './index.style';
import { SendButtonStyled } from './index.style';

import {
  getRandomizeAudio,
  getCompetitionById,
  choosing,
  getTaskProcess,
} from '../../../actions/team';

const emotionOptions = [
  {
    label: 'Bình thường (Neutral)',
    value: 1,
  },
  {
    label: 'Tức giận (Angry)',
    value: 2,
  },
  {
    label: 'Buồn bã (Sad)',
    value: 3,
  },
  {
    label: 'Vui vẻ (Happy)',
    value: 4,
  },
]

const sentenceTypeOptions = [
  {
    label: 'Câu trần thuật',
    value: 1,
  },
  {
    label: 'Câu hỏi',
    value: 2,
  },
  {
    label: 'Câu cảm thán',
    value: 3,
  },
  {
    label: 'Câu mệnh lệnh',
    value: 4,
  },
  
];

const EmotionConstruction = ({ match, history }) => {
  const [disableCheckBox, setDisabeCheckBox] = useState(false);
  const [disableSend, setDisableSend] = useState(true); 
  const [emotionChoice, setEmotionChoice] = useState([]);
  const [sentenceType, setSentenceType] = useState(0);
  const {
    currentAudio,
    competition,
    completed,
    processTask,
  } = useSelector(state => state.team);

  const dispatch = useDispatch();

  useEffect(() => {
    if (completed) {
      return history.push('/team/finish-emotion-competition');
    }
    dispatch(getCompetitionById(match.params.competitionId));
    dispatch(getTaskProcess(match.params.competitionId));
    dispatch(getRandomizeAudio(match.params.competitionId));
  }, [completed, dispatch, history, match.params.competitionId]);

  useEffect(() => {
    onTrackChange(currentAudio);
  }, [currentAudio]);

  const audio = useRef();

  const onTrackChange = src => {
    if (src != null) {
      audio.current.pause();
      audio.current.load();
    }
  };

  useEffect(() => {
    if(emotionChoice.length === 0 || sentenceType === 0) {
      setDisableSend(true);
      return;
    }
    setDisableSend(false);
  }, [emotionChoice, sentenceType])

  const onEmotionCheckBoxChange = (e) => {
    if(e.length > 2) {
      return;
    }
    setEmotionChoice(e);

  }
  
  const onChangeErrorCheck = (e) => {
    setDisabeCheckBox(e.target.checked);
    if(e.target.checked){
      setEmotionChoice([0]);
    }
    else setEmotionChoice([]);
  }

  const onSentenceTypeChange = (e) => {
    setSentenceType(e.target.value);
  }

  const onSend = () => {
    setEmotionChoice([]);
    setDisabeCheckBox(false);
    setDisableSend(true);
    setSentenceType(0);
    dispatch(choosing({
      emotionChoice,
      sentenceType,
    }, currentAudio._id));
  }

  return (
    <StyleDataConstruction>
      <div className="app-data-construction">
        {competition && (
          <h2 className="flex-center">{competition.name.toUpperCase()}</h2>
        )}
        <div className="main">
          <Col span={16}>
            <Row type="flex" justify="center">
              <Col span={24}>
                <Row justify="center" >
                  <span
                    className="text"
                    style={{
                      fontStyle: 'italic',
                      marginBottom: '10px',
                      fontSize: '25px',
                    }}
                  >
                    {`Đánh giá cảm xúc  ${processTask.numberOfCompletedEmotionAudios }/${processTask.totalAmountOfAudio }`}
                  </span>
                </Row>
                <Row type="flex" justify="start" align="middle">
                  <Col span={24}>
                    <div className="controls box-shadow">
                      {currentAudio && (
                        <audio controls style={{ width: '100%' }} ref={audio}>
                          <source
                            src={`${process.env.REACT_APP_API_DOMAIN}${currentAudio.link}`}
                          />
                          {/* <track kind="captions" /> */}
                        </audio>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row type="flex" justify="start" align="middle" style={{ marginTop: '20px'}} >
                  <Col span={24}>
                    <div className="controls box-shadow">
                      <Typography 
                        style={{
                          marginLeft: '5px',
                          fontWeight: 'bold'
                        }}
                      >
                        Lựa chọn sắc thái (có thể chọn tối đa 2/4)</Typography>
                      <Checkbox.Group 
                        disabled={disableCheckBox} 
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginLeft: '5px'
                        }}
                        options={emotionOptions}
                        onChange={onEmotionCheckBoxChange}
                        value={emotionChoice}
                      >
                      </Checkbox.Group>
                      <Divider/>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}>
                        <Checkbox  
                          style={{
                            marginLeft: '5px',
                          }} 
                          onChange={onChangeErrorCheck}
                          checked={disableCheckBox}
                        >
                          Lỗi (Không thuộc sắc thái nào kể trên / nhiễu lớn / nhiều người nói)
                        </Checkbox>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row type="flex" justify="start" align="middle" style={{ marginTop: '20px'}} >
                  <Col span={24}>
                    <div className="controls box-shadow">
                      <Typography 
                        style={{
                          marginLeft: '5px',
                          fontWeight: 'bold'
                        }}
                      >
                        Lựa chọn kiểu câu</Typography>
                      <Radio.Group 
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          marginTop: '10px',
                          marginLeft: '5px',
                          height: '110px'
                        }}
                        options={sentenceTypeOptions}
                        onChange={onSentenceTypeChange}
                        value={sentenceType}
                      >
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
                <Row 
                  style={{
                    marginTop:'36px',
                  }}
                >
                  <SendButtonStyled>
                    <Button 
                      onClick={onSend}
                      disabled={disableSend}             
                    >
                      Gửi
                    </Button>
                  </SendButtonStyled>
                </Row>
              </Col>
            </Row>
          </Col>
        </div>
      </div>
    </StyleDataConstruction>
  );
};

export default EmotionConstruction;
