export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const GET_LIST_USERS = 'GET_LIST_USERS';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const ADD_TEST_SUCCESS = 'ADD_TEST_SUCCESS';
export const ADD_TEST_FAILED = 'ADD_TEST_FAILED';
export const SET_STEP_CREATE_TEST = 'SET_STEP_CREATE_TEST';
export const SET_DIRECTORY_AUDIO_PATH = 'SET_DIRECTORY_AUDIO_PATH';
export const ADD_USER_AND_FILEUPLOAD_SUCCESS = 'ADD_USER_CHOSEN_SUCCESS';
export const ADD_USER_USER_AND_FILEUPLOAD_FAILED = 'ADD_USER_CHOSEN_FAILED';
export const RESET_TEST = 'RESET_TEST';
export const GET_PUBLIC_TEST_SUCCESS = 'GET_PUBLIC_TEST_SUCCESS';
export const GET_PRIVATE_TEST_SUCCESS = 'GET_PRIVATE_TEST_SUCCESS';
export const SET_USER_CHOSEN = 'SET_USER_CHOSEN';
export const SET_TEST_CURRENTLY = 'SET_TEST_CURRENTLY';
export const GET_AUDIO_FOR_USER = 'GET_AUDIO_FOR_USER';
export const SET_POINT_FOR_AUDIO = 'SET_POINT_FOR_AUDI';
export const GET_LIST_TEST = 'GET_LIST_TEST';
export const GET_TEST_BY_ID = 'GET_TEST_BY_ID';
export const GET_AUDIO_BY_TEST_AND_VOICE = 'GET_AUDIO_BY_TEST_AND_VOICE';
export const RESET_AUDIO_STORE = 'RESET_AUDIO_STORE';
export const UPDATE_REAL_USER_FOR_AUDIO = 'UPDATE_REAL_USER_FOR_AUDIO';
export const RESET_USER_CHOOSEN = 'RESET_USER_CHOOSEN';
export const GET_ALL_AUDIO_BY_TEST_ID = 'GET_ALL_AUDIO_BY_TEST_ID';
export const INCREASE_INDEX_AUDIO = 'INCREASE_INDEX_AUDIO';
export const DECREASE_INDEX_AUDIO = 'DECREASE_INDEX_AUDIO';
export const SET_INDEX_AUDIO = 'SET_INDEX_AUDIO';
export const GET_INDEX_AUDIO = 'GET_INDEX_AUDIO';
export const SET_AUDIOS = 'SET_AUDIOS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_AUDIOS = 'RESET_AUDIOS';
export const ADD_VOICE = 'ADD_VOICE';
export const GET_VOICES = 'GET_VOICES';
export const UPDATE_PUBLIC_TEST_AFTER_USER_JOIN =
  'UPDATE_PUBLIC_TEST_AFTER_USER_JOIN';
export const SET_MAX_INDEX_AUDIO = 'SET_MAX_INDEX_AUDIO';

export const GET_LIST_COMPETITION = 'GET_LIST_COMPETITION';
export const JOIN_COMPETITIONS = 'JOIN_COMPETITION';
export const FINISH_TASK = 'FINISH_TASK';
export const GET_RANDOMIZE_AUDIO = 'GET_RANDOMIZE_AUDIO';
export const GET_LIST_TRANSCRIPT = 'GET_LIST_TRANSCRIPT';
export const GET_COMPETITION_BY_ID = 'GET_COMPETITION_BY_ID';
export const RESET_TEAM_STATE = 'RESET_TEAM_STATE';
export const GET_TASK_PROCESS = 'GET_TASK_PROCESS';

export const INCEASE_COMPLETED_AUDIO = 'INCEASE_COMPLETED_AUDIO';
